export interface SignalRNotification<TData = any> {
    entityId: string;
    entityType: EntityType;
    actionType: ActionType;
    extraAttributes: any;
    notificationSource: NotificationSource;
    data: TData;
}

export enum EntityType {
    PurchaseOrder = 0,
    Stockltem = 1
}

export enum ActionType {
    Create = 0,
    Update = 1,
    Delete = 2
}

export enum NotificationSource {
    Edara3Backend = 0,
    Edara3_Edara2_Connector = 1,
    Edara2_5_Edara2_Connector = 2
}
